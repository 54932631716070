/* eslint-disable @next/next/no-img-element */
import { ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";
import CustomButton from "../ratl/utility/CustonButton";
import { useFloat } from "@/lib";
import { RT_STUDIO_RIL } from "@/data";
import { useRouter } from "next/navigation";

const PricingBody = ({
  type,
  title,
  subTitle,
  price,
  interval,
  buttonText,
  pricingHeader,
  features,
}: {
  type: string;
  title: string;
  subTitle: string;
  price: string;
  interval: string;
  buttonText: string;
  pricingHeader: string;
  features: string[];
}) => {
  const { float, handleFloat } = useFloat([false, false]);
  const router = useRouter();
  return (
    <div
      className={`  ${
        type == "Custom" && "glowing-border"
      } p-[1px] rounded-[20px]`}
    >
      <div
        className={`flex flex-col flex-wrap w-fit max-w-[576px] md:px-12 md:py-10 p-5 h-fit rounded-[20px] font-inter bg-card-bg text-white gap-6`}
      >
        <div className="">
          <Badge
            type={type}
            className="font-semibold uppercase leading-[12px]"
          />
        </div>
        <div>
          <h1 className="text-xl font-medium">{title}</h1>
        </div>
        <div>
          <p className="text-sm">{subTitle}</p>
        </div>
        <div className="text-5xl">
          <p>{price}</p>
        </div>
        <div>
          <p>{interval}</p>
        </div>
        <div>
          {/* <button
          className={`py-2 px-4 w-fit h-11 font-medium rounded-[22px] text-sm ${
            type == "Starter"
              ? "bg-[#353343] text-white "
              : "text-rt-black-t1 bg-white/80 "
          } hover:bg-opacity-80`}
        >
          {buttonText}
        </button> */}
          {type == "Starter" ? (
            <CustomButton
              text={buttonText}
              animationRequired={false}
              bgType={"transparent"}
              onMouseEnter={() => handleFloat(true, 1)}
              onMouseLeave={() => handleFloat(false, 1)}
              controller={float[1]}
              btnSize="small"
              onClick={() => window.open(RT_STUDIO_RIL, "_blank")}
              showMobile={true}
            />
          ) : (
            <div className="w-fit">
              <CustomButton
                text={buttonText}
                animationRequired={false}
                bgType={"solid"}
                onMouseEnter={() => handleFloat(true, 1)}
                onMouseLeave={() => handleFloat(false, 1)}
                controller={float[1]}
                btnSize="small"
                onClick={() => router.push("/contact-us")}
                className=" transition w-fit"
                showMobile={true}
              />
            </div>
          )}
        </div>
        <div>
          <p>{pricingHeader}</p>
        </div>
        {type == "Starter" && (
          <div>
            <p>What's Included :</p>
          </div>
        )}
        <div>
          <Features features={features} type={type} />
        </div>
      </div>
    </div>
  );
};

export default PricingBody;

export const Badge = ({
  type,
  className,
  arrow,
}: {
  type: string;
  className?: string;
  arrow?: boolean;
}) => {
  return (
    <button
      className={cn(
        "px-3 py-1 text-[11px] text-[#646283] font-normal w-fit rounded-[62.53px] border-[1px] border-solid border-[rgba(255,255,255,0.15)] bg-card-bg backdrop-filter backdrop-blur-[1.5px] flex justify-center items-center gap-1 font-inter cursor-default",
        className
      )}
    >
      {type} {arrow && <ChevronRight className="w-5 h-5" />}
    </button>
  );
};

const Features = ({ features, type }: { features: string[]; type: string }) => {
  return (
    <div className="flex flex-col gap-5">
      {features.map((feature) => {
        return (
          <div className="flex justify-start gap-2">
            <div
              className={`rounded-full w-5 h-5 p-1 flex justify-center items-center ${
                type == "Starter" ? "bg-[#8B8994]" : "bg-[#AFA2F9]"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.3314 1.29842C11.5145 1.48148 11.5145 1.77828 11.3314 1.96134L4.45646 8.83633C4.36856 8.92431 4.24932 8.97366 4.125 8.97366C4.00068 8.97366 3.88145 8.92431 3.79355 8.83633L0.668543 5.71134C0.485485 5.52828 0.485486 5.23148 0.668545 5.04843C0.851604 4.86537 1.1484 4.86537 1.33146 5.04843L4.125 7.84199L10.6685 1.29843C10.8516 1.11537 11.1484 1.11537 11.3314 1.29842Z"
                  fill="#020014"
                  stroke="#020014"
                />
              </svg>
            </div>
            <p>{feature}</p>
          </div>
        );
      })}
    </div>
  );
};
