import { RT_INTRO_VIDEO_CDN } from "@/data";
import React from "react";

interface YouTubeEmbedProps {
  videoId: string; // YouTube video ID
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoId }) => {
  return (
    <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-[20px]">
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube Video"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
